body {
  background-color: #ffffff;
  color: #000000;
}

body.dark {
  background-color: #121212;
  color: white;
}

.dark .bg-slate-200 {
  background-color: #2e2e2e; 
}

.dark .text-white {
  color: #f0f0f0; 
}

.dark .bg-slate-800 {
  background-color: #333333; 
}


.dark h1, .dark h2, .dark h3, .dark h4, .dark h5, .dark h6,
.dark p, .dark span, .dark div, .dark li {
  color: #f0f0f0; 
}
